<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="d-flex">
          Location Guide
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-container>
          <div>
            <div v-if="currentStep === 0">
              <p class="info-text">
                If you’re unable to enable location access on Peeps, you may see a disabled location
                icon on the GPS button within the map, and you may not be able to see your current
                location.
                <v-img class="my-2" contain src="@/assets/img/Location/enable-0.jpg"></v-img>
              </p>
              <div class="font-weight-bold">
                <v-btn class="text-none" color="primary" @click="currentStep = 1">
                  Click here
                </v-btn>
                and follow these steps to re-enable location permissions for Peeps
              </div>
            </div>
            <div v-if="currentStep === 1">
              <p class="text-h6">
                1. Tap the bottom left icon in Safari to open the website settings menu.
              </p>
              <v-img class="my-2" contain src="@/assets/img/Location/enable-1.jpg"></v-img>
            </div>
            <div v-if="currentStep === 2">
              <p class="text-h6">
                2. Select the three dots (<v-icon>mdi-dots-horizontal</v-icon>) to access more
                settings.
              </p>
              <v-img class="my-2" contain src="@/assets/img/Location/enable-2.jpg"></v-img>
            </div>
            <div v-if="currentStep === 3">
              <div class="text-h6">
                <p>
                  3. Scroll down and choose Website Settings.
                </p>
                <p>
                  4. Under Location, change the permission to Allow.
                </p>
              </div>
              <v-img class="my-2" contain src="@/assets/img/Location/enable-3.jpg"></v-img>
            </div>
            <div v-if="currentStep > 0">
              <v-btn color="primary" v-if="currentStep !== 3" @click="currentStep += 1"
                >Next
                </v-btn
              >
              <v-btn
                v-if="currentStep !== 1"
                color="secondary"
                class="ml-2"
                @click="currentStep -= 1"
              >
                Back
              </v-btn>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Geo  from '../../services/geolocation'
export default {
  data: () => {
    return {
      hasPermission: false,
      tab: 'problem', //'problem' | 'solution'
      currentStep: 0,
      steps: [
        {
          value: 0,
          title: 'Click the menu icon at the bottom before URL'
        },

        {
          value: 1,
          title: 'Click the three dots'
        },

        {
          value: 2,
          title: 'Scroll down to website settings'
        },

        {
          value: 3,
          title: 'Allow Location'
        }
      ],
      timeout: null
    };
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.locationPermissionTroubleshoot;
      },
      set(value) {
        this.$store.commit('setDialog', { key: 'locationPermissionTroubleshoot', value });
      }
    },
    isIos() {
      return /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
    }
  },
  methods: {
    onTouch(cb) {
      function processFn(e) {
        window.addEventListener(e, cb);
      }

      processFn('touchend');
      processFn('click');
    },
    checkGeoPermission(ev) {
      window.removeEventListener(ev?.type, this.checkGeoPermission);
      if(this.timeout){
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.timeout = setTimeout(()=>{
        const geo = new Geo()
        geo.getPermission().catch(()=>{
          this.$store.commit('setDialog', { key: 'locationPermissionTroubleshoot', value: true });
          this.$store.commit('setDialog', { key: 'locationPermissionTroubleshootButton', value: true });
        })
      }, 5000)
    }
  },
  mounted() {
    if(this.isIos)
    this.onTouch(this.checkGeoPermission);
  }
};
</script>
